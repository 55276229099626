import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient  } from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SocketIoConfig, SocketIoModule} from 'ng-socket-io';
import {TextMessagePageModule} from './text-message/text-message.module';
import {EnvService} from './services/env.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar';

const apiSocketURL: string = EnvService.getApiSocketUrl();
// const config: SocketIoConfig = {url: 'http://192.168.2.102:3000' , options: ''};
const config: SocketIoConfig = {url: apiSocketURL, options: ''};

// AoT requires an exported function for factories

import {StreamingMedia} from '@ionic-native/streaming-media/ngx';
import { IonicStorageModule } from '@ionic/storage';
import {BLE} from '@ionic-native/ble/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import {Zeroconf} from '@ionic-native/zeroconf/ngx';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        RoundProgressModule,
        TextMessagePageModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        SocketIoModule.forRoot(config),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        StatusBar,
        BLE,
        SplashScreen,
        QRScanner,
        Zeroconf,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        NativeStorage,
        StreamingMedia
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
