import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Storage } from '@ionic/storage';
import decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private storage: Storage,
        private route: ActivatedRoute
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser = this.authService.isLoggedIn;
        if (currentUser) {
            console.log(this.storage.get('token'));
            // console.log(decode(this.storage.getItem('token')));
            console.log(currentUser);
            // authorised so return true
             return true;
        }
        // not logged in so redirect to login page with the return url

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        console.log('parameter : user=',urlParams.get('u'));
        let myUser=urlParams.get('u');
        if (!myUser){
            myUser="1";
        }
        this.router.navigate(['/landing'],{ queryParams: { user: 'vis'+myUser+"@demo.com" } });
        return false;
    }
}
