import {Injectable} from '@angular/core';
import { Zeroconf } from '@ionic-native/zeroconf/ngx';


@Injectable({
    providedIn: 'root'
})
export class EnvService {
    API_KEY = '0d8fb0b5-c5cb-41a0-b1af-e2c717bc889f';
    BASE_IP = '192.168.0.100'; // IPFIXE / DEMONSTRATEUR POINT SERVICE
    // BASE_IP = '10.3.141.1'; // WIFI TAMTAMPRO
    // BASE_IP = '10.3.141.119'; // WIFI TAMTAMPRO + MAC
    // BASE_IP = 'raspberrypi.local';
    // BASE_IP = 'localhost'; // IPLOCALE
    // BASE_IP = 'bylifineo-derval.local'; // IP NUC
    //  BASE_URL = 'http://' + this.BASE_IP + ':88';
     BASE_URL = 'http://euroshop.tamtamdeals.com:89';
    //BASE_URL = 'http://tml.interiorgeo.com:89';
    //BASE_URL = 'http://localhost:89';
    API_URL = this.BASE_URL + '/api/';
    WS_URL = this.BASE_URL + '/ws/';
    // API_SOCKET_URL = 'http://' + this.BASE_IP + ':3000';

    // API_URL = 'https://cloud.tamtampros.com/api/';
    constructor(private zeroconf: Zeroconf) {
    }

    static getApiSocketUrl() {
         // return 'http://' + 'localhost' + ':3000';
         //  return 'http://' + '192.168.0.100' + ':3000';
         return 'http://euroshop.tamtamdeals.com:3000';
        // return 'http://' + 'raspberrypi.local' + ':3000';
    }
}
