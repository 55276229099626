import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Storage} from '@ionic/storage';
import {EnvService} from './env.service';
import {User} from '../models/user';
import {UserDetail} from '../models/userDetail';
import {MapTagPoint} from '../models/mapTagPoint';
import {MapPlan} from '../models/mapPlan';
import {AlertService} from './alert.service';
import {NavController} from '@ionic/angular';
import {GroupLevelLights} from '../models/groupLevelLights';
import {MapObject} from '../models/mapObject';
import {MapInterestPoint} from '../models/mapInterestPoint';

// import decode from 'jwt-decode';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isLoggedIn = false;
    token: any;
    userDetailStorage: UserDetail;
    mapTagPoints: MapTagPoint[];
    mapPlans: MapPlan[];
    mapInterestPoints: MapInterestPoint[];

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private env: EnvService,
        private alertService: AlertService,
        private navCtrl: NavController
    ) {
    }

    login(email: String, password: String) {
        return this.http.post(this.env.API_URL + 'auth/login',
            {email: email, password: password}
        ).pipe(
            tap(token => {
                console.log('we get token from login');
                this.token = token;
                this.isLoggedIn = true;

                this.storage.set('token', token)
                    .then(
                        () => {
                            console.log('Token Stored');
                            console.log(token);

                        },
                        error => console.error('Error storing item', error)
                    );
                return token;
            }),
        );
    }

    register(fName: String, lName: String, email: String, password: String) {
        return this.http.post(this.env.API_URL + 'auth/register',
            {fName: fName, lName: lName, email: email, password: password}
        );
    }

    logout() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        this.storage.remove('token');
        return this.http.get(this.env.API_URL + 'auth/logout', {headers: headers})
            .pipe(
                tap(data => {
                        this.storage.remove('token').then(
                            res => {
                                this.isLoggedIn = false;
                                delete this.token;
                                console.log('logout finish');
                                return data;
                            }
                        );

                    }, (error) => {
                        this.isLoggedIn = false;
                        delete this.token;
                        console.log('logout finish with error');
                        return error;
                    }
                )
            );
    }

    updateMyThing(mac: String, type: String) {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        return this.http.post<any>(this.env.API_URL + 'auth/update-my-thing', {mac: mac, type: type}, {headers: headers})
            .pipe(
                tap(result => {
                    console.log(result);
                    return result;
                })
            );
    }


    user() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        return this.http.get<any>(this.env.API_URL + 'auth/user', {headers: headers})
            .pipe(
                tap(user => {
                    console.log(user);
                    return user;
                })
            );
    }

    sendMqtt(deviceAddress: String, deviceOrder: String) {
        // deviceAddress = deviceAddress.replace('/\//g', '%2F');
        console.log('MQTT : ' + deviceAddress + ':' + deviceOrder);
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log(this.env.API_URL + 'auth/send-mqtt/' + deviceAddress + '/' + deviceOrder);
        return this.http.get(this.env.API_URL + 'auth/send-mqtt/' + deviceOrder + '/' + deviceAddress
            , {headers: headers}).subscribe(data => {
            console.log('result :', data);
        }, error => {
            console.log(error);
        });
    }


    getGroupLevelLight(idPlan: number) {
        // deviceAddress = deviceAddress.replace('/\//g', '%2F');
        console.log('idPlan : ' + idPlan);
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log(this.env.API_URL + 'auth/group-level-light/' + idPlan);

        return this.http.get<GroupLevelLights[]>(this.env.API_URL + 'auth/group-level-light/' + idPlan, {headers: headers})
            .pipe(
                tap(groupLevelLights => {

                    // console.log(user);
                    return groupLevelLights;
                })
            );
    }

    updateGroupeLevelLight(idGroupLevelLight, lightLevel) {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        return this.http.get(this.env.API_URL + 'auth/update-group-level-light/' + idGroupLevelLight + '/'
            + parseInt(lightLevel, 0)
            , {headers: headers}).subscribe(data => {
            console.log('result :', data);
        }, error => {
            console.log(error);
        });
    }

    groupOrderLevelLight(idGroup: number, groupOrderLevelLight: number) {
        // deviceAddress = deviceAddress.replace('/\//g', '%2F');
        console.log('GROUP : ' + idGroup + ':' + groupOrderLevelLight);
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log(this.env.API_URL + 'auth/group-order-level_light/' + idGroup + '/' + groupOrderLevelLight);
        return this.http.get(this.env.API_URL + 'auth/group-order-level-light/' + idGroup + '/' + groupOrderLevelLight
            , {headers: headers}).subscribe(data => {
            console.log('result :', data);
        }, error => {
            console.log(error);
        });
    }


    mapTagPointLevelLight(idMapTagPoint: number, mapTagPointLevelLight: number) {
        // deviceAddress = deviceAddress.replace('/\//g', '%2F');
        console.log('MTP : ' + idMapTagPoint + ':' + mapTagPointLevelLight);
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });

        return this.http.get(this.env.API_URL + 'auth/map-tag-point-level-light/' + idMapTagPoint + '/' + mapTagPointLevelLight
            , {headers: headers}).subscribe(data => {
            console.log('result :', data);
        }, error => {
            console.log(error);
        });
    }

    userDetail() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log('go to ' + this.env.API_URL + 'auth/user-detail');
        return this.http.get<UserDetail>(this.env.API_URL + 'auth/user-detail', {headers: headers})
            .pipe(
                tap(user => {
                        this.userDetailStorage = user;
                        // console.log(user);
                        return user;
                    }, error1 => {
                        console.log('ERROR !');
                        console.log(error1.error.message);
                        if (error1.error.message === 'Unauthenticated.') {
                            this.alertService.presentToast('Votre session n\'est plus valide');
                            this.logout().subscribe(
                                data => {
                                    this.navCtrl.navigateRoot('/landing');
                                },
                                (error) => {
                                    this.navCtrl.navigateRoot('/landing');
                                }
                            );
                        }
                    }, () => {
                        console.log('complete ?');
                    }
                )
            );
    }

    getMapTagPoints() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log('go to ' + this.env.API_URL + 'auth/map-tag-points');
        return this.http.get<MapTagPoint[]>(this.env.API_URL + 'auth/map-tag-points', {headers: headers})
            .pipe(
                tap(mapTagPoints => {
                    this.mapTagPoints = mapTagPoints;
                    // console.log(user);
                    return mapTagPoints;
                })
            );
    }


    getUsers() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        return this.http.get<User[]>(this.env.WS_URL + 'get-users/' + this.env.API_KEY, {headers: headers})
            .pipe(
                tap(users => {
                    console.log("all users : ");
                    console.log(users);
                    return users;
                })
            );
    }

    getThingDeps() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        return this.http.get<any>(this.env.WS_URL + 'get-thing-deps/' + this.env.API_KEY, {headers: headers})
            .pipe(
                tap(data => {
                    // console.log(user);
                    return data;
                })
            );
    }

    getThings() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log('go to ' + this.env.API_URL + 'auth/things');
        return this.http.get<MapObject[]>(this.env.API_URL + 'auth/things', {headers: headers})
            .pipe(
                tap(things => {
                     console.log(things);
                    return things;
                })
            );
    }
    getMapInterestPoints() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log('go to ' + this.env.API_URL + 'auth/all-point-interests');
        return this.http.get<MapInterestPoint[]>(this.env.API_URL + 'auth/all-point-interests', {headers: headers})
            .pipe(
                tap(mapInterestPoints => {
                    this.mapInterestPoints = mapInterestPoints;
                    // console.log(user);
                    return mapInterestPoints;
                })
            );
    }
    getMapPlans() {
        const headers = new HttpHeaders({
            'Authorization': this.token['token_type'] + ' ' + this.token['access_token']
        });
        console.log('go to ' + this.env.API_URL + 'auth/map-plans');
        return this.http.get<MapPlan[]>(this.env.API_URL + 'auth/map-plans', {headers: headers})
            .pipe(
                tap(mapPlans => {
                    this.mapPlans = mapPlans;
                    // console.log(user);
                    return mapPlans;
                })
            );
    }

    getToken() {
        return this.storage.get('token').then(
            data => {
                this.token = data;
                if (this.token != null) {
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            },
            error => {
                this.token = null;
                this.isLoggedIn = false;
            }
        );
    }

    isRight(right: string) {
        // console.log('call isRight');
        // console.log(this.userDetailStorage);
        if (this.userDetailStorage) {
            if (this.userDetailStorage.permissions) {
                for (const permission of this.userDetailStorage.permissions) {
                    if (permission.name === right) {
                        return true;
                    }
                }
                return false;
            } else {
                return false;
            }
        }
    }
}
