import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';


@Component({
    selector: 'app-text-message',
    templateUrl: './text-message.page.html',
    styleUrls: ['./text-message.page.scss'],
})
export class TextMessagePage implements OnInit {
    modalMessage: string;

    constructor(
        private modalController: ModalController,
        private navParams: NavParams
    ) {
    }

    ngOnInit() {
        console.table(this.navParams);
        this.modalMessage = this.navParams.data.paramMessage;
    }

    async closeModal() {
        const onClosedData: string = 'Wrapped Up!';
        await this.modalController.dismiss(onClosedData);
    }

}
