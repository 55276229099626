import {Component} from '@angular/core';
import {Platform, NavController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthService} from './services/auth.service';
import {AlertService} from './services/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    public appPages = [];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authService: AuthService,
        private navCtrl: NavController,
        private alertService: AlertService,
        private _translate: TranslateService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            // Commenting splashScreen Hide, so it won't hide splashScreen before auth check
            // this.splashScreen.hide();
            this.authService.getToken();
            this._initTranslate();
        });
    }

    private _initTranslate() {
        // Set the default language for translation strings, and the current language.
        this._translate.setDefaultLang('fr');


        if (this._translate.getBrowserLang() !== undefined) {
            this._translate.use(this._translate.getBrowserLang());
        } else {
            this._translate.use('fr'); // Set your language here
        }
    }

}
