import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guard/auth.guard';

const routes: Routes = [
      { path: 'landing', loadChildren: './pages/landing/landing.module#LandingPageModule'}
    , { path: '', loadChildren: './tabs/tabs.module#TabsPageModule', canActivate: [AuthGuard] },
  { path: 'video', loadChildren: './tabs/video/video.module#VideoPageModule' },
  { path: 'lifi-test-receiver', loadChildren: './tabs/lifi-test-receiver/lifi-test-receiver.module#LifiTestReceiverPageModule' },
  { path: 'lifi-test-emitter', loadChildren: './tabs/lifi-test-emitter/lifi-test-emitter.module#LifiTestEmitterPageModule' },
  { path: 'healthcare', loadChildren: './tabs/healthcare/healthcare.module#HealthcarePageModule' },
  { path: 'command-center', loadChildren: './tabs/command-center/command-center.module#CommandCenterPageModule' },
  { path: 'text-message', loadChildren: './text-message/text-message.module#TextMessagePageModule' },
  { path: 'visiteur', loadChildren: './tabs/visiteur/visiteur.module#VisiteurPageModule' },
  { path: 'valise', loadChildren: './tabs/valise/valise.module#ValisePageModule' },
  { path: 'map', loadChildren: './tabs/map/map.module#MapPageModule' },
  { path: 'map3d', loadChildren: './tabs/map3d/map3d.module#Map3dPageModule' },
  { path: 'hello', loadChildren: './tabs/hello/hello.module#HelloPageModule' }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
